import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import MenuAppBar from "../menu-app-bar";
import AnimalesTable from "../animales-table";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {    
    marginTop: 10
  },
}));

const Main = ({ centro, onLogout }) => {
  const isLogged = Boolean(centro);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MenuAppBar loggedIn={isLogged} onLogout={onLogout} />
      <div className={classes.container}>
        <AnimalesTable centro={centro} redirectToLogin={onLogout} />
      </div>
    </div>
  );
};
Main.propTypes = {
  centro: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
};
Main.displayName = "Main";

export default Main;
