import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import FilterListIcon from "@material-ui/icons/FilterList";
import DownloadIcon from "@material-ui/icons/GetApp";
import TablePaginationActions from "./table-pagination-actions";
import AnimalesTableFilters from "../animales-table-filters";
import {
  estadosAnimal,
  especies,
  sexo,
  apiUrl,
  tokenExpiredMessage,
} from "../constants";

const parseFilterValues = (filterValues) => {
  const filtersArray = Object.entries(filterValues).reduce(
    (acc, [key, value]) => {
      if (!value) return acc;
      if (Array.isArray(value)) {
        return [...acc, ...value.map((item) => `${key}=${item}`)];
      }
      if (key === "fechaRecogida") {
        const fechas = [];
        if (value.fromDate) {
          fechas.push(
            `fechaDesde=${value.fromDate.toISOString().split("T")[0]}`
          );
        }
        if (value.toDate) {
          console.log(value.toDate);
          fechas.push(`fechaHasta=${value.toDate.toISOString().split("T")[0]}`);
        }
        return [...acc, ...fechas];
      }
      return [...acc, `${key}=${value}`];
    },
    []
  );
  return filtersArray.length > 0 ? `&${filtersArray.join("&")}` : "";
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  tableContainer: {
    maxWidth: "95vw",
    marginLeft: "auto",
    marginRight: "auto",
  },
  tableWithFilers: {
    maxWidth: "calc(95vw - 260px)",
    marginLeft: "2.5vw",
    marginRight: "auto",
  },
  table: {
    minWidth: 700,
  },
  title: {
    flex: "1 1 100%",
  },
  noResults: {
    padding: 50,
  },
});

const AnimalesTable = (props) => {
  const classes = useStyles();
  const [animales, setAnimales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filterValues, setFilterValues] = useState({
    fechaRecogida: {
      fromDate: null,
      toDate: null,
    },
    especie: Object.keys(especies),
    estadoActual: Object.keys(estadosAnimal),
    sexo: Object.keys(sexo),
  });

  useEffect(() => {
    async function fetchData() {
      const filters = parseFilterValues(filterValues);
      const response = await axios
        .get(
          `${apiUrl}/animales?page=${page + 1}&limit=${rowsPerPage}&centro=${
            props.centro
          }${filters}`
        )
        .catch((error) => {          
          if (error.message === tokenExpiredMessage) {
            props.redirectToLogin();
          }
        });
      const { data = {} } = response || {};
      if (data.results && data.total) {
        setAnimales(data.results);
        setCount(data.total);
      }
      setLoading(false);
    }
    fetchData();
  }, [props, page, rowsPerPage, filterValues]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toogleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };

  const handleApplyFilters = (filtersNextValues) => {
    setFilterValues(filtersNextValues);
    setPage(0);
  };

  const downloadResults = async () => {
    const filters = parseFilterValues(filterValues);
    const response = await axios
      .get(`${apiUrl}/export?centro=${props.centro}${filters}`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
        data: {},
      })
      .catch((error) => {
        if (error.message === tokenExpiredMessage) {
          return;
        }

        console.error(error);
      });
    if (!response) {
      // Show message, toast or something
      console.error("Export response is empty!!!");
      return;
    }

    const fileName =
      response.headers["content-disposition"].split("filename=")[1];
    const blob = new Blob([response.data], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <div
      className={filtersOpen ? classes.tableWithFilers : classes.tableContainer}
    >
      {filtersOpen && (
        <AnimalesTableFilters
          onApplyFilters={handleApplyFilters}
          filterValues={filterValues}
          onClose={() => {
            setFiltersOpen(false);
          }}
          facets={{
            estadoActual: [
              {
                estado: "PARA_ADOPTAR",
                label: "Para adoptar",
                total: 10,
              },
            ],
            fechaRecogida: {
              fromDate: null,
              toDate: null,
            },
          }}
        />
      )}
      {!loading && (
        <TableContainer component={Paper}>
          <Toolbar>
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Animales
            </Typography>
            <React.Fragment>
              <Tooltip title="Exportar">
                <IconButton onClick={downloadResults} aria-label="exportar">
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Filtrar lista">
                <IconButton onClick={toogleFilters} aria-label="filtrar lista">
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          </Toolbar>
          {animales.length === 0 && (
            <Typography variant="h6" className={classes.noResults}>
              No hay animales que cumplan con la búsqueda
            </Typography>
          )}
          {animales.length > 0 && (
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
              align="center"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">ID</StyledTableCell>
                  <StyledTableCell align="center">Nombre</StyledTableCell>
                  <StyledTableCell align="center">Especie</StyledTableCell>
                  <StyledTableCell align="center">Sexo</StyledTableCell>
                  <StyledTableCell align="center">
                    Fecha de Recogida
                  </StyledTableCell>
                  <StyledTableCell align="center">Localidad</StyledTableCell>
                  <StyledTableCell align="center">Centro</StyledTableCell>
                  <StyledTableCell align="center">
                    Estado Actual
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {animales.map((animal) => (
                  <StyledTableRow key={animal.id}>
                    <StyledTableCell component="th" scope="row" align="center">
                      {animal.id}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {animal.nombre}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {animal.especie}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {animal.sexo}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {animal.fechaRecogida}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {animal.localidadRecogida}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {animal.ayuntamiento}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {animal.estadoActual}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    labelRowsPerPage="Resultados por pagina:"
                    rowsPerPageOptions={[20, 50, 100]}
                    colSpan={3}
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "resultados por pagina" },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          )}
        </TableContainer>
      )}
    </div>
  );
};

AnimalesTable.propTypes = {
  centro: PropTypes.string.isRequired,
  redirectToLogin: PropTypes.func.isRequired,
};

export default AnimalesTable;
