import React, { useState } from "react";
import axios from "axios";
import Main from "./main";
import Login from "./login";
import { apiUrl } from "./constants";

axios.interceptors.request.use(
  (config) => {
    const { origin } = new URL(config.url);
    const allowedOrigins = [apiUrl];
    const token = localStorage.getItem("token");
    if (allowedOrigins.includes(origin)) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function App() {
  const storedJwt = localStorage.getItem("token");
  const storedCentro = localStorage.getItem("centro");
  const storedUser =
    storedJwt && storedCentro
      ? {
          jwt: storedJwt,
          centro: storedCentro,
        }
      : null;
  const [user, setUser] = useState(storedUser);

  const getJwt = async (values) => {
    const response = await axios({
      method: "post",
      url: `${apiUrl}/login`,
      data: values,
    }).catch(console.error);

    const { data = {} } = response || {};
    if (data.token && data.centro) {      
      localStorage.setItem("token", data.token);
      localStorage.setItem("centro", data.centro);
      setUser({
        jwt: data.token,
        centro: data.centro,
      });
    }    
  };

  const handleLogout = () => {    
    localStorage.removeItem("token");
    localStorage.removeItem("centro");
    setUser(null);
  };

  return (
    <>
      {!user && <Login onSubmit={getJwt} />}
      {user && user.jwt && user.centro && (
        <Main centro={user.centro} onLogout={handleLogout} />
      )}
    </>
  );
}

export default App;
