import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import CheckboxFilter from "./checkbox-filter";
import RecogidaDateRangeFilter from "./recogida-date-range-filter";
import SearchFilter from "./search-filter";
import { estadosAnimal, especies, sexo } from "../constants";

const useStyles = makeStyles({
  button: {
    margin: 20,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    minHeight: 64,
    justifyContent: "flex-end",
  },
  drawerContainer: {
    overflow: "auto",
  },
});

const AnimalesTableFilters = (props) => {
  const [currentFilters, setCurrentFilters] = useState(props.filterValues);
  const classes = useStyles();
  return (
    <Drawer
      anchor="right"
      variant="persistent"
      open={true}
      onClose={props.onClose}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <SearchFilter
        className={classes.button}
        filterTitle="ID / Nombre"
        filterValue={currentFilters.search}
        onUpdateFilter={(search) => {
          setCurrentFilters({ ...currentFilters, search });
        }}
      />
      <CheckboxFilter
        defaultExpanded={true}
        filterTitle="Estado Actual"
        filterValue={currentFilters.estadoActual}
        options={estadosAnimal}
        onUpdateFilter={(estadoActual) => {
          setCurrentFilters({ ...currentFilters, estadoActual });
        }}
      />
      <RecogidaDateRangeFilter
        defaultExpanded={false}
        filterValue={currentFilters.fechaRecogida}
        onUpdateFilter={(fechaRecogida) => {
          setCurrentFilters({ ...currentFilters, fechaRecogida });
        }}
      />
      <CheckboxFilter
        defaultExpanded={false}
        filterTitle="Especie"
        filterValue={currentFilters.especie}
        options={especies}
        onUpdateFilter={(especie) => {
          setCurrentFilters({ ...currentFilters, especie });
        }}
      />
      <CheckboxFilter
        defaultExpanded={false}
        filterTitle="Sexo"
        filterValue={currentFilters.sexo}
        options={sexo}
        onUpdateFilter={(sexo) => {
          setCurrentFilters({ ...currentFilters, sexo });
        }}
      />
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={() => {
          props.onApplyFilters(currentFilters);
        }}
      >
        Aplicar
      </Button>
    </Drawer>
  );
};

AnimalesTableFilters.propTypes = {
  onApplyFilters: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  filterValues: PropTypes.shape({
    search: PropTypes.string,
    estadoActual: PropTypes.arrayOf(PropTypes.string).isRequired,
    especie: PropTypes.arrayOf(PropTypes.string).isRequired,
    fechaRecogida: PropTypes.shape({
      fromDate: PropTypes.instanceOf(Date),
      toDate: PropTypes.instanceOf(Date),
    }).isRequired,
  }).isRequired,
  facets: PropTypes.shape({
    estadoActual: PropTypes.arrayOf(
      PropTypes.shape({
        estado: PropTypes.string,
        label: PropTypes.string,
        total: PropTypes.number,
      })
    ).isRequired,
  }).isRequired,
};
AnimalesTableFilters.displayName = "AnimalesTableFilters";

export default AnimalesTableFilters;
