import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles({
  filtersColumn: {
    flexDirection: "column",
    display: "flex",
  },
});

const RecogidaDateRangeFilter = (props) => {
  const { onUpdateFilter, filterValue, defaultExpanded } = props;
  const classes = useStyles();
  const handleDateChange = (dateField, date) => {
    onUpdateFilter({
      ...filterValue,
      [dateField]: date,
    });
  };
  return (
    <>
      <Accordion defaultExpanded={defaultExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Fecha de Recogida</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.filtersColumn}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              format="dd/MM/yyyy"
              margin="normal"
              id="recogida-desde"
              label="Fecha inicio"
              value={filterValue.fromDate}
              onChange={(date) => handleDateChange("fromDate", date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              format="dd/MM/yyyy"
              margin="normal"
              id="recogida-gasta"
              label="Fecha fin"
              value={filterValue.toDate}
              onChange={(date) => handleDateChange("toDate", date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

RecogidaDateRangeFilter.displayName = "RecogidaDateRangeFilter";
RecogidaDateRangeFilter.propTypes = {
  onUpdateFilter: PropTypes.func.isRequired,
  filterValue: PropTypes.shape({
    fromDate: PropTypes.instanceOf(Date),
    toDate: PropTypes.instanceOf(Date),
  }).isRequired,
  defaultExpanded: PropTypes.bool.isRequired,
};

export default RecogidaDateRangeFilter;
