const apiUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001"
    : "https://censo-api.protectoramodepran.com";
console.log(apiUrl);

const estadosAnimal = {
  ADOPTADO: "Adoptado",
  DEVUELTO_A_COLONIA: "Devuelto a colonia",
  DEVUELTO_A_PROPIETARIO: "Devuelto a propietario",
  DIFUNTO: "Difunto",
  EN_ACOGIDA: "En acogida",
  EN_ACOGIDA_SOLIDARIA: "En acogida solidaria",
  EN_CUARENTENA: "En cuarentena",
  INGRESADO_EN_CLINICA: "Ingresado en clinica",
  PARA_ADOPTAR: "Para adoptar",
  TRASLADADO: "Trasladado",
};

const especies = {
  PERRO: "perro",
  GATO: "gato",
  OTROS: "otros",
};

const sexo = {
  MACHO: "macho",
  HEMBRA: "hembra",
  INDEFINIDO: "indefinido",
};

const tokenExpiredMessage = "Request failed with status code 401";

module.exports = { estadosAnimal, especies, sexo, apiUrl, tokenExpiredMessage };
