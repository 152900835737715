import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import GreenCheckbox from "./green-checkbox";

const useStyles = makeStyles({
  filtersColumn: {
    flexDirection: "column",
    display: "flex",
  },
});

const CheckboxFilter = (props) => {
  const { options, onUpdateFilter, filterValue, filterTitle, defaultExpanded } = props;
  const classes = useStyles();  

  return (
    <>
      <Accordion defaultExpanded={defaultExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{filterTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.filtersColumn}>
        {Object.entries(options).map(([key, value]) => (
            <FormControlLabel
              key={key}
              label={value}
              control={
                <GreenCheckbox
                  checked={filterValue.includes(key)}
                  onChange={() => {
                    const isNowPresent = !filterValue.includes(key);
                    const filterNextValue = isNowPresent
                      ? [...filterValue, key]
                      : filterValue.filter((cod) => cod !== key);
                    onUpdateFilter(filterNextValue);
                  }}
                  name={key}
                />
              }
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

CheckboxFilter.displayName = "CheckboxFilter";
CheckboxFilter.propTypes = {
  filterTitle: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  onUpdateFilter: PropTypes.func.isRequired,
  filterValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultExpanded: PropTypes.bool.isRequired,
};

export default CheckboxFilter;
