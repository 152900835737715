import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const SearchFilter = (props) => {
  const { onUpdateFilter, filterValue, filterTitle, className } = props;

  return (
    <TextField
      className={className}
      label={filterTitle}
      value={filterValue}
      onChange={(event) => {
        onUpdateFilter(event.target.value);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

SearchFilter.displayName = "SearchFilter";
SearchFilter.propTypes = {
  filterTitle: PropTypes.string.isRequired,
  onUpdateFilter: PropTypes.func.isRequired,
  filterValue: PropTypes.string,
  className: PropTypes.string,
};

export default SearchFilter;
