import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
  root: {
    height: "100vh",
    backgroundColor: "lightgray",
    display: "grid",
    padding: 20,
  },
  paper: {
    minWidth: 275,
    width: "30%",
    maxWidth: 400,
    margin: `30px auto`,
    flexDirection: "column",
    display: "flex",
  },
  field: {
    margin: 20,
  },
});

const Login = ({ onSubmit }) => {
  const [username, setUsername] = React.useState();
  const [password, setPassword] = React.useState();
  const classes = useStyles();

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({ username, password });
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <Paper className={classes.paper}>
          <img src="/logo.png" alt="Modepran" />
          <TextField
            className={classes.field}
            required
            id="filled-required"
            label="Required"
            name="username"
            variant="filled"
            defaultValue={username}
            onChange={(event) => setUsername(event.target.value)}
          />
          <TextField
            className={classes.field}
            required
            id="filled-password-input"
            label="Password"
            type="password"
            name="password"
            autoComplete="current-password"
            variant="filled"
            defaultValue={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.field}
          >
            Entrar
          </Button>
        </Paper>
      </form>
    </div>
  );
};

Login.protoTypes = {
  onSubmit: PropTypes.func.isRequired,
};
Login.displayName = "Login";

export default Login;
